import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layouts'
import HeadMeta from '../components/HeadMeta'
import * as ds from '../uiLibrary'
import { GatsbyHeroImage } from '../components/heroes'
import { FixedWhiteContainer, TriangleDiv } from '../components'
import { Headline } from '../uiLibrary/Typographyv2'
import { LocationContext } from '../pages/index'
import { Champion } from '../components/champions/Champion'

export const SuperUsersPage = ({ data, ...props }) => {
  const page = data.allSuperUsersJson.edges[0].node
  const bannerImage = data.banner.edges[0].node.childImageSharp

  return (
    <LocationContext.Provider value={props.location}>
      <Layout>
        <HeadMeta
          pageTitle={page.pageTitle || 'Tonit'}
          pageDescription={page.pageDescription}
          socialTitle={page.socialTitle}
          socialDescription={page.socialDescription}
          socialImage={page.socialImage}
        />
        <ds.Background background={'#000000'}>
          <ds.FixedWidthContainer
            py={[ds.spacing.s5, ds.spacing.s6, ds.spacing.s6]}
          >
            <GatsbyHeroImage
              url={bannerImage.fluid}
              alt="Tonit - the app for motorcyclists"
            />
            <Headline
              textAlign={'center'}
              px={[ds.spacing.s3, ds.spacing.s4, ds.spacing.s5]}
              fontSize={[ds.typeScale.t6, ds.typeScale.t7, '4.998rem']}
              color={ds.brandColors.lightGreen}
            >
              {page.heroHeading}
            </Headline>
          </ds.FixedWidthContainer>
        </ds.Background>
        <ds.Background
          background={'#202020'}
          pb={[ds.spacing.s2, ds.spacing.s4, ds.spacing.s6]}
        >
          <ds.FixedWidthContainer
            bg="white"
            py={ds.spacing.s5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <TriangleDiv display={['none', 'block', 'block']} />
            <ds.CenteredImg
              src={page.introImage}
              maxWidth={['200px', '400px']}
              alt={page.introImageAlt}
            />
            <FixedWhiteContainer
              title={page.introTitle}
              header={page.introHeader}
              body={page.introBody}
            />
          </ds.FixedWidthContainer>
          <ds.FixedWidthContainer bg="white">
            <div style={{ padding: `0 ${ds.spacing.s3}` }}>
              <ds.Row>
                {(page.featuredChampions || []).map((c, i) => (
                  <ds.Column span={4} key={`${c?.name}${i}`}>
                    <Champion {...c} />
                  </ds.Column>
                ))}
              </ds.Row>
            </div>
          </ds.FixedWidthContainer>
        </ds.Background>
      </Layout>
    </LocationContext.Provider>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        banner: allFile(
          filter: { name: { regex: "/^tonitchampionsheader*/" } }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        allSuperUsersJson {
          edges {
            node {
              heroHeading
              introTitle
              introHeader
              introBody
              introImage
              introImageAlt
              secondarySection {
                title
                subheader
                firstBody
              }
              featuredChampions {
                name
                bioImage
                profileLink
                city
                state
                imageAlt
                divColor
                flip
                bio
              }
              pageTitle
              socialTitle
              pageDescription
              socialImage
              socialDescription
            }
          }
        }
      }
    `}
    render={data => <SuperUsersPage data={data} {...props} />}
  />
)
